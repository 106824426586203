<template>
  <div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Generar DTE
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex flex-column">
                                    <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        style="border-bottom: 1px solid #000;"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-bind="attrs" v-on="on" placeholder="Todos" clearable @click:clear="limpiarRango" v-model="dateRangeText" solo readonly ></v-text-field>
                                        </template>
                                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates" ></v-date-picker>
                                    </v-menu>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <h3 class="title">Seleccione el(los) pedido(s) que desea generar DTE:</h3> 
                    </v-card-text>
                    <v-card-text>    
                        <v-select
                            v-model="documentType"
                            :items="documentTypesList"
                            :rules="[v => !!v || 'Debes elegir un tipo de documento']"
                            label="Tipo de documento"
                            required
                        ></v-select>                       
                    </v-card-text>
                    <v-card-text>                
                        <v-btn @click="finish" :disabled="!selected.length || selected.length > 10 || !documentType" small color="success" class="float-left" >
                            <v-icon left dark>mdi-format-list-checks</v-icon> Generar DTE
                        </v-btn> 
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="table"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.created_at="{ item }">
                                
                                <td class="text-center">
                                    {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                </td>
                                
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="text-center">
                                    <b-button size="sm"  pill >{{item.order_state.name}}</b-button>
                                </td>
                            </template>
                            
                            <!--<template v-slot:item.acciones="{ item }">
                                <td>
                                    <v-btn small color="success" dark  >
                                        <v-icon left dark v-on="on" @click="generarDTEDialog({order_id: item.id, store_id: item.store_id, current_state: item.current_state})">mdi-file</v-icon>Generar DTE
                                    </v-btn>
                                </td>
                            </template>-->

                            
                            <template v-slot:item.acciones="{ item }">
                                <td class="d-flex">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn small color="success" dark v-on="on" @click="generarDTEDialog({order_id: item.id, store_id: item.store_id, current_state: item.current_state})" >
                                                <v-icon left dark >mdi-file</v-icon>Generar DTE
                                            </v-btn>
                                        </template>
                                        <span>Generar DTE</span>
                                    </v-tooltip>
                                </td>
                            </template>
                            
                            
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
    <dialog-generar-dte @success="abrirMensajeSuccess" @danger="abrirMensajeError"></dialog-generar-dte>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogGenerarDte from "@/views/oms/Facturaciones/DialogGenerarDte";
import format from 'date-fns/format';
export default {
    components: {
        DialogGenerarDte
    },
    data(){
        return {
            search: '',
            table: [],
            selected: [],
            billersList: [],
            selectBiller: undefined,
            documentTypesList: [],
            documentType: undefined,
            dates: [],
            menu1: false,
            dateRangeText: '',
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Generar DTE", route: "/envios/generar-dte" },
        ]);
        this.fetchData();
        this.fetchDocumentTypes();
    },
    methods: {
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        finish()
        {            
            var vm = this;
            if(this.documentType == undefined){
                vm.abrirMensajeError("Debe seleccionar un tipo de documento");
                return;
            }
            this.axios({
                url: 'orders/bulk_generate_dte',
                method: 'POST',
                data: {
                    orders: this.selected.map( e => e.id ),
                    biller: this.selectBiller,
                    document_type: this.documentType
                }
            }).then( response => {
                vm.abrirMensajeSuccess("Proceso finalizado");
                this.fetchData();
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value){
            //console.log('value', value);
            //console.log('convertido', this.$moment(value, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY hh:mm:ss'));
            if( !this.dateRangeText || !this.dates.length ){
                return true;
            }else{
                if( this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) && this.$moment(value).isSameOrBefore(this.$moment(this.dates[1])) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/without_billing',
                method: 'POST'
            }).then( response => {
                vm.table = response.data.data;
            }).catch( error => {
                console.log(error);
            });
        },
        /*fetchBillers(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'invoices/billers/data_select_input/'+'1'
            }).then( response => {
                vm.billersList = response.data;
            }).catch( error => {
                console.log(error);
            });
        },*/
        fetchDocumentTypes(){
            var vm = this;
            this.axios({
                method: 'GET', url: 'invoices/document_types'
            }).then( response => {
                vm.documentTypesList = response.data;
            }).catch( error => {
                console.log(error);
            });
        },
        formatearRango()
        {
            if(!this.dates.length){
                this.dateRangeText = '';
            }else{
                this.dateRangeText = this.dates.join(' ~ ');
            }
        },
        limpiarRango()
        {
            this.dates = [];
        },
        generarDTEDialog(datos) {
            this.$emit("openDialogGenerarDte", datos);
        },
        generarDTE(itemId) {
            var vm = this;
            this.axios({
                url: "orders/generar_dte/" + itemId,
                method: "POST",
            })
            .then((response) => {
                //console.log("generarDTE response", response);
                vm.abrirMensajeSuccess("DTE enviado a Generar");
                this.fetchData();
            })
            .catch((error) => {
                console.log(error);
            });
        },
        abrirMensajeSuccess(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Información`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
            this.fetchData();
        },
        abrirMensajeError(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Error`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
            this.fetchData();
        }
    },
    computed: {
        headers(){ 
            return [
                { text: '#...', align: 'left', sortable: true, value: 'id', },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Fecha Ingreso', value: 'created_at', filter: v => this['filtroRangoFechas'](v) },
                { text: 'Estado', value: 'estado' },
                { text: 'Fuente', value: 'fuente' },
                { text: "Generar DTE", value: "acciones", sortable: false },
            ];
        }
    }
}
</script>